import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    SelectInput,
    SubmitButton,
    Switch,
    Textarea,
    TextInput,
} from '../../../compositions';
import { HeardAboutSource } from '../../../constants/contact';
import { ClientType, ContactFormData } from '../../../entities/Contact/Contact';
import { getEnumKeyByEnumValue } from '../../../helpers/enum';
import { sanitizeAllHtml } from '../../../helpers/sanitizeHtml';
import { scrollIntoView } from '../../../helpers/scroll';
import useTrans from '../../../hooks/useTrans';
import { FormOption } from '../../../types';
import { ContactFormErrors, validateContactFormData } from './validations';

import './ContactForm.scss';

interface ContactFormProps {
    isLoading: boolean;
    isSuccessful: boolean;
    onSubmit: (formData: ContactFormData) => void;
    className?: string;
}

const ContactForm: FC<ContactFormProps> = ({
    isLoading,
    isSuccessful,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<ContactFormErrors>({});

    const [clientType, setClientType] = useState<string>(ClientType.visitor);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [heardAbout, setHeardAbout] = useState<HeardAboutSource>();
    const [comment, setComment] = useState<string>('');

    const clientTypeOptions: [FormOption, FormOption] = [
        {
            label: trans('containers.contactForm.clientType.visitor'),
            value: ClientType.visitor,
        },
        {
            label: trans('containers.contactForm.clientType.owner'),
            value: ClientType.owner,
        },
    ];

    const heardAboutOptions: FormOption[] = [
        HeardAboutSource.instagram,
        HeardAboutSource.facebook,
        HeardAboutSource.tikTok,
        HeardAboutSource.google,
        HeardAboutSource.gym,
        HeardAboutSource.wordOfMouth,
    ].map(option => ({
        label: trans(`containers.contactForm.heardAbout.options.${option}`),
        value: option,
    }));

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const formData: ContactFormData = {
            clientType: clientType as ClientType,
            name: sanitizeAllHtml(name),
            email: sanitizeAllHtml(email),
            phoneNumber: sanitizeAllHtml(phoneNumber),
            heardAbout: heardAbout ? trans(`containers.contactForm.heardAbout.options.${heardAbout}`) : undefined,
            comment: sanitizeAllHtml(comment),
        };

        const [errors, hasErrors] = validateContactFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    const handleHeardAboutSelectChange = (selected: FormOption): void => {
        const heardAboutKey = getEnumKeyByEnumValue(HeardAboutSource, selected.value);

        if (heardAboutKey) {
            setHeardAbout(HeardAboutSource[heardAboutKey]);
        }
    };

    useEffect((): void => {
        if (isSuccessful) {
            setName('');
            setEmail('');
            setPhoneNumber('');
            setHeardAbout(undefined);
            setComment('');
        }
    }, [isSuccessful]);

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`contact-form ${className}`}
        >
            <Switch
                label={trans('containers.contactForm.clientType.label')}
                hideLabel
                name="client-type"
                options={clientTypeOptions}
                value={clientType}
                error={formErrors.clientType}
                onChange={setClientType}
                className="contact-form__input"
            />

            <TextInput
                required
                label={trans('containers.contactForm.name.label')}
                value={name}
                placeholder={trans('containers.contactForm.name.placeholder')}
                error={formErrors.name}
                onChange={setName}
                className="contact-form__input"
            />

            <TextInput
                required
                type="email"
                label={trans('containers.contactForm.email.label')}
                value={email}
                placeholder={trans('containers.contactForm.email.placeholder')}
                error={formErrors.email}
                onChange={setEmail}
                className="contact-form__input"
            />

            <TextInput
                required
                type="tel"
                label={trans('containers.contactForm.phone.label')}
                value={phoneNumber}
                placeholder={trans('containers.contactForm.phone.placeholder')}
                error={formErrors.phoneNumber}
                onChange={setPhoneNumber}
                className="contact-form__input"
            />

            <SelectInput
                additionalLabel={trans('common.optional')}
                label={trans('containers.contactForm.heardAbout.label')}
                name="gym"
                value={heardAboutOptions.find(option => option.value === heardAbout)}
                options={heardAboutOptions}
                placeholder={trans('containers.contactForm.heardAbout.placeholder')}
                onChange={handleHeardAboutSelectChange}
                className="contact-form__input"
            />

            <Textarea
                required
                label={trans('containers.contactForm.comments.label')}
                value={comment}
                placeholder={trans('containers.contactForm.comments.placeholder')}
                error={formErrors.comment}
                onChange={setComment}
                className="contact-form__input"
            />

            <div className="contact-form__submit-wrapper">
                <SubmitButton
                    isLoading={isLoading}
                    text={trans('containers.contactForm.submit.button')}
                    className="contact-form__submit-button"
                />

                <p className="contact-form__submit-underline">
                    {trans('containers.contactForm.submit.underline')}
                </p>
            </div>
        </form>
    );
};

export default ContactForm;
