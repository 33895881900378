/* eslint-disable camelcase */
import { defaultLocale, Language } from '../../constants/locale';
import { Image, PrismicImage } from '../@slices/Image/Image';
import { DateResource } from '../Date/Date';
import { CallToAction, PrismicLink } from '../Link/Link';
import { defaultSeo, PrismicSeo, Seo } from '../Seo/Seo';

export const prismicLocales: Record<Language, string> = {
    [Language.ar]: 'ar-sa',
    [Language.de]: 'de-de',
    [Language.enGb]: 'en-gb',
    [Language.enUs]: 'en-us',
    [Language.es]: 'es-es',
    [Language.fr]: 'fr-fr',
    [Language.nl]: 'nl-nl',
};

export enum PrismicType {
    about = 'about_yanga',
    article = 'article',
    faq = 'faq',
    privacyStatement = 'privacy_statement',
    vacancy = 'vacancy',
    workAt = 'work_at_yanga',
    yourClub = 'yanga_in_your_club',
}

export enum PrismicSliceType {
    centeredText = 'centered_text',
    faqList = 'faq_list',
    faqItem = 'faq_item',
    featuredImage = 'featured_image',
    image = 'image',
    imageGrid = 'image_grid',
    pagePreview = 'page_preview',
    partnerGrid = 'partner_grid',
    table = 'table',
    text = 'text',
    twoColumnText = 'two_column_text',
    vacancyCallToAction = 'vacancy_call_to_action',
    vacancyExpectations = 'vacancy_expectations',
    vacancyOverview = 'vacancy_overview',
    video = 'video',
}

export interface PrismicAlternateLanguage {
    id: string;
    uid?: string;
    type: string;
    lang: string;
}

export interface PrismicDefaultPageTemplate {
    title: string;
    title_colour: string;
    intro: string;
    call_to_action_link?: PrismicLink;
    call_to_action_label?: string;
    poster?: PrismicImage;
    droplet: string;
    seo: PrismicSeo[];
    body: PrismicSlice[];
}

export interface PrismicDefaultPageData {
    language: Language;
    title: string;
    titleColour?: PrismicSliceTitleColour;
    intro: string;
    callToAction?: CallToAction;
    poster?: Image;
    posterDroplet?: number;
    seo: Seo;
    slices: PrismicSlice[];
}

export const defaultPrismicDefaultPageData: PrismicDefaultPageData = {
    language: defaultLocale.language,
    title: '',
    intro: '',
    seo: defaultSeo,
    slices: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PrismicDocument<Template = any> {
    id: string;
    uid: string;
    url?: string;
    type: string;
    href: string;
    tags: string[];
    slugs: string[];
    lang?: string;
    alternate_languages: PrismicAlternateLanguage[];
    first_publication_date: DateResource | null;
    last_publication_date: DateResource | null;
    data: Template;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PrismicSearchResponse<Template = any> {
    page: number;
    results_per_page: number;
    results_size: number;
    total_results_size: number;
    total_pages: number;
    next_page: string;
    prev_page: string;
    results: PrismicDocument<Template>[];
}

export interface PrismicSlice<MainTemplate = unknown, RepeatableTemplate = unknown> {
    id: string;
    items: RepeatableTemplate[];
    primary: MainTemplate;
    slice_label: unknown;
    slice_type: PrismicSliceType;
}

export interface PrismicText {
    spans: unknown[];
    text: string;
    type: string;
}

export type PrismicTextSlice = PrismicSlice<{
    content: PrismicText[];
}>;

export enum PrismicSliceTitleSize {
    small = 'small',
    big = 'big',
}

export enum PrismicSliceTitleColour {
    black = 'black',
    red = 'red',
    pink = 'pink',
    blue = 'blue',
    purple = 'purple',
    yellow = 'yellow',
    orange = 'orange',
    cyan = 'cyan',
    green = 'green',
}
/* eslint-enable camelcase */
