import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    Reducer,
    useContext,
    useEffect,
    useLayoutEffect,
    useReducer,
    useState,
} from 'react';

import ct from 'countries-and-timezones';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { CookieType } from '../constants/cookieSettings';
import { CountryData, defaultCountry, getCountryFromName } from '../constants/countries';
import { getLocaleFromBrowser, LocaleData } from '../constants/locale';
import { getTranslatedLocation } from '../helpers/url';
import { useCookieSettings } from './CookieContext';
import { useLocaleDispatch } from './LocaleContext';
import { useParams } from './ParamContext';

export const CountryContext = createContext<CountryData>(defaultCountry);
export const CountryDispatchContext = createContext((countryData => countryData) as Dispatch<CountryData>);

export const useCountry = () => useContext(CountryContext);
export const useCountryDispatch = () => useContext(CountryDispatchContext);

const countryReducer: Reducer<CountryData, CountryData> = (prevState, countryData) => countryData;

const ContinentProvider: FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const cookieSettings = useCookieSettings();
    const functionalCookiesAccepted = cookieSettings[CookieType.functional];

    const dispatchLocale = useLocaleDispatch();

    const [storedLocale] = useLocalStorage<LocaleData>('locale');
    const [storedCountry, storeCountry] = useLocalStorage<CountryData>('country', undefined);

    const [country, dispatch] = useReducer(countryReducer, storedCountry || defaultCountry);

    const [localizedRedirect, setLocalizedRedirect] = useState<string>('');

    useLayoutEffect((): void => {
        const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezoneCountry = ct.getCountryForTimezone(timezone);

        if (!storedCountry && timezoneCountry) {
            const userCountry = getCountryFromName(timezoneCountry.name);

            dispatch(userCountry);

            if (!storedLocale) {
                const browserLocale = getLocaleFromBrowser();
                const defaultLocale = browserLocale || userCountry.mainLocale;

                if (defaultLocale) {
                    dispatchLocale(defaultLocale);

                    const { language } = defaultLocale;
                    const translatedRoute = getTranslatedLocation(language, location, params);

                    setLocalizedRedirect(translatedRoute);
                }
            }
        }
    }, []);

    useEffect((): void => {
        if (localizedRedirect) {
            navigate(localizedRedirect, { replace: true });
        }
    }, [localizedRedirect]);

    useEffect((): void => {
        if (functionalCookiesAccepted) {
            storeCountry(country);
        }
    }, [country, functionalCookiesAccepted]);

    return (
        <CountryContext.Provider value={country}>
            <CountryDispatchContext.Provider value={dispatch}>
                {children}
            </CountryDispatchContext.Provider>
        </CountryContext.Provider>
    );
};

export default ContinentProvider;
