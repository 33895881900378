import { objectContainsValues } from '../../helpers/object';
import trans from '../../helpers/trans';
import {
    stringContainsValue,
    stringDoesNotExceedLength,
    stringHasMinimumLength,
    stringMatchesRegEx,
} from '../../helpers/validation';

export type FormError<Value = string> = Value | undefined;
export type FormErrors<FormData, FormSubData = string> = Partial<Record<keyof FormData, FormError<FormSubData>>>;

export type FormValidation<Errors> = [FormErrors<Errors>, boolean];
export type OptionalFormValidation<FormData> = Partial<Record<keyof FormData, boolean>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateForm = <Errors extends Record<string, any>>(errors: Errors): FormValidation<Errors> => {
    const hasErrors = objectContainsValues<Errors>(errors);

    return [
        errors,
        hasErrors,
    ];
};

export const validateRequiredString = (attribute: string, string?: string): FormError => {
    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateName = (attribute: string, string: string): FormError => {
    const minLength = 2;
    const maxLength = 25;

    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!stringHasMinimumLength(string, minLength)) {
        return trans('errors.stringFailsMinimumLength', {
            attribute: trans(`errors.attributes.${attribute}`),
            length: String(minLength),
        });
    }

    if (!stringDoesNotExceedLength(string, maxLength)) {
        return trans('errors.stringExceedsLength', {
            attribute: trans(`errors.attributes.${attribute}`),
            length: String(maxLength),
        });
    }

    return undefined;
};

export const validateEmailAddress = (email: string): FormError => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!stringContainsValue(email)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.email'),
        });
    }

    if (!stringMatchesRegEx(email, emailRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.email'),
            example: trans('errors.examples.email'),
        });
    }

    return undefined;
};

export const validatePhoneNumber = (phoneNumber: string): FormError => {
    // eslint-disable-next-line max-len
    const phoneNumberRegex = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
    const usaPhoneNumberRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

    if (!stringMatchesRegEx(phoneNumber, phoneNumberRegex) && !stringMatchesRegEx(phoneNumber, usaPhoneNumberRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.phoneNumber'),
            example: trans('errors.examples.phoneNumber'),
        });
    }

    return undefined;
};

export const validateShortDescription = (attribute: string, description: string): FormError => {
    const maxDescriptionLength = 256;

    if (!stringContainsValue(description)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    if (!stringDoesNotExceedLength(description, maxDescriptionLength)) {
        return trans('errors.stringExceedsLength', {
            attribute: trans(`errors.attributes.${attribute}`),
            length: String(maxDescriptionLength),
        });
    }

    return undefined;
};
