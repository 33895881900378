import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import CookieProvider from './context/CookieContext';
import CountryProvider from './context/CountryContext';
import LocaleProvider from './context/LocaleContext';
import ParamProvider from './context/ParamContext';
import ScrollProvider from './context/ScrollContext';
import { isProduction } from './helpers';
import { registerServiceWorker } from './helpers/pwa';
import { store } from './redux/store';

import './styles/global.scss';

const container = document.getElementById('app-root') as Element;
const root = createRoot(container);

const helmetContext = {};

root.render(
    <StoreProvider store={store}>
        <BrowserRouter>
            <ParamProvider>
                <CookieProvider>
                    <LocaleProvider>
                        <CountryProvider>
                            <HelmetProvider context={helmetContext}>
                                <ScrollProvider>
                                    <App />
                                </ScrollProvider>
                            </HelmetProvider>
                        </CountryProvider>
                    </LocaleProvider>
                </CookieProvider>
            </ParamProvider>
        </BrowserRouter>
    </StoreProvider>,
);

// Service worker registration
if (isProduction) {
    registerServiceWorker().catch(error => {
        console.log('ServiceWorker registration failed:', error);
    });
}
